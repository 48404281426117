import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./index.css";

const Section = ({ children, anchor, verticalSpacing, separator, height }) => {
  const styles = classNames("root", { verticalSpacing, separator });

  return (
    <section style={{ height }} styleName={styles} id={anchor}>
      {children}
    </section>
  );
};

Section.propTypes = {
  anchor: PropTypes.string,
  verticalSpacing: PropTypes.bool,
  children: PropTypes.node.isRequired,
  separator: PropTypes.bool,
  height: PropTypes.string,
};

Section.defaultProps = {
  anchor: null,
  verticalSpacing: false,
  separator: false,
  height: "100%",
};

export default Section;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/dedupe";

import "./index.css";

export default class Text extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(["h1", "h2", "body", "small"]),
    fontFamily: PropTypes.oneOf(["open-sans-cond", "proxima-nova"]),
    color: PropTypes.oneOf(["white", "grey", "light-grey", "teal"]),
    weight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    className: PropTypes.string,
  };

  static defaultProps = {
    variant: "body",
    fontFamily: "proxima-nova",
    color: "white",
    weight: "regular",
    className: "",
  };

  render() {
    const {
      children,
      variant,
      fontFamily,
      color,
      weight,
      className,
    } = this.props;
    const styles = classNames("root", {
      [variant]: true,
      [fontFamily]: true,
      [color]: true,
      [weight]: true,
    });
    const TextComponent = variant === "body" ? "p" : variant;

    return (
      <TextComponent styleName={styles} className={className}>
        {children}
      </TextComponent>
    );
  }
}

import React from "react";

import Text from "root/components/Text";
import Section from "root/components/Section";
import phone from "root/assets/images/phone.png";
import pin from "root/assets/images/pin.png";
import clock from "root/assets/images/clock.png";

import "./index.css";

export default function HomeContactInfo() {
  return (
    <Section verticalSpacing={false}>
      <div styleName="root">
        <Text
          styleName="title"
          variant="h1"
          color="grey"
          fontFamily="open-sans-cond"
        >
          Contactos
        </Text>

        <div styleName="info">
          <div styleName="info-block column">
            <div styleName="with-icon">
              <i>
                <img src={phone} alt="phone icon" />
              </i>

              <div styleName="phones">
                <Text color="grey">
                  <a href="tel:+351252088774">(+351) 252088774</a>
                </Text>
                <Text color="grey">
                  <a href="tel:+351919607231">(+351) 919607231</a>
                </Text>
              </div>
            </div>

            <div styleName="column with-icon">
              <i>
                <img src={clock} alt="schedule icon" />
              </i>
              <Text color="grey">Segunda a Sexta</Text>
              <Text styleName="ml" color="grey">
                09:00h – 20:00h
              </Text>
            </div>
          </div>

          <address styleName="with-icon">
            <i>
              <img src={pin} alt="address icon" />
            </i>
            <a href="https://goo.gl/maps/MTjgGQcRM4YiEGmm6" styleName="address">
              <Text color="grey">
                Rua João José Ribeiro Ed. Queens Park 134 Loja 4
              </Text>
              <Text color="grey">4760-041 Antas, Vila Nova de Famalicão</Text>
            </a>
          </address>
        </div>
      </div>
    </Section>
  );
}

import React from "react";
import PropTypes from "prop-types";

import "./index.css";

function InputText({ placeholder, name, type }) {
  if (type === "textarea") {
    return <textarea styleName="root" placeholder={placeholder} name={name} />;
  }

  return <input styleName="root" placeholder={placeholder} name={name} />;
}

InputText.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["textarea", "input"]),
};

InputText.defaultProps = {
  type: "input",
};

export default InputText;

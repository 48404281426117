/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image/withIEPolyfill";
import Background from "../Background";

import "./index.css";

export default class BackgroundImage extends Component {
  static propTypes = {
    image: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
    }).isRequired,
    imageProps: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
    opacity: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { alt, opacity, image, children, imageProps } = this.props;
    const imageJsx = (
      <Img
        style={{ opacity }}
        styleName="image"
        objectFit="cover"
        alt={`image-${alt}`}
        fluid={image.fluid}
        {...imageProps}
      />
    );

    return <Background backgroundChildren={imageJsx}>{children}</Background>;
  }
}

import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

import Text from "root/components/Text";
import Button from "root/components/Button";
import logoSrc from "root/assets/vectors/logo.svg";

import "./index.css";

function renderLink(url, text, currentPage) {
  if (currentPage === url) return null;

  return (
    <Link to={url}>
      <Text variant="small" weight="bold">
        {text}
      </Text>
    </Link>
  );
}

function Header({ currentPage }) {
  return (
    <header styleName="root">
      <div styleName="brand">
        <Link to="/">
          <img src={logoSrc} alt="Logo Osteovida" />
        </Link>
      </div>

      <nav styleName="nav">
        {renderLink("/services", "SERVIÇOS", currentPage)}
        {renderLink("/", "HOME", currentPage)}

        <div styleName="contact-us">
          <a href="#contactform">
            <Button>MARCA A TUA CONSULTA</Button>
          </a>
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default Header;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./index.css";

function ArrowBox({ direction, bordered }) {
  const style = direction === "right" ? { transform: "rotate(180deg)" } : null;
  const styles = classNames("root", {
    bordered,
  });

  return (
    <div styleName={styles}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" style={style}>
        <polyline points="36.61 30.36 27.91 38.34 37.04 46.71" />
        <line x1="51" x2="28" y1="38.5" y2="38.5" />
      </svg>
    </div>
  );
}

ArrowBox.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
  bordered: PropTypes.bool,
};

ArrowBox.defaultProps = {
  direction: "left",
  bordered: true,
};

export default ArrowBox;

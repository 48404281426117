/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { useSwipeable } from "react-swipeable";

import ArrowBox from "../ArrowBox";
import Section from "../Section";
import useInterval from "./useInterval";
import useDetectJavascript from "./useDetectJavascript";

import "./index.css";

function Gallery({ slides, autoplay, darkArrows, style }) {
  const [ref, inView] = useInView({ threshold: 0.8 });
  const hasJavascript = useDetectJavascript();
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrev = () =>
    setSlideIndex(slideIndex === 0 ? slides.length - 1 : slideIndex - 1);
  const handleNext = () =>
    setSlideIndex(slideIndex === slides.length - 1 ? 0 : slideIndex + 1);
  const handleIntervalNext = () => (inView && autoplay ? handleNext() : null);
  const handleSwipeLeft = () =>
    slideIndex === slides.length - 1 ? null : handleNext();
  const handleSwipeRight = () => (slideIndex === 0 ? null : handlePrev());

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  useInterval(handleIntervalNext, 5000, slideIndex);

  if (!hasJavascript) {
    return (
      <div styleName="root">
        <div styleName="slide" key="0">
          {slides[0]}
        </div>
      </div>
    );
  }

  return (
    <div styleName="root" ref={ref} {...handlers} style={style}>
      <div
        styleName="slides"
        style={{ transform: `translateX(${-100 * slideIndex}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} styleName="slide">
            {slide}
          </div>
        ))}
      </div>

      <div className="gallery-arrows-wrapper">
        <Section height="0px">
          <div
            styleName={`arrows ${darkArrows ? "arrows-dark" : ""}`}
            className="arrows"
          >
            <button onClick={handlePrev} type="button">
              <ArrowBox direction="left" />
            </button>

            <button onClick={handleNext} type="button">
              <ArrowBox direction="right" />
            </button>
          </div>
        </Section>
      </div>
    </div>
  );
}

Gallery.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  autoplay: PropTypes.bool,
  darkArrows: PropTypes.bool,
  style: PropTypes.object,
};

Gallery.defaultProps = {
  autoplay: true,
  darkArrows: false,
  style: {},
};

export default Gallery;

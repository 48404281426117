import React from "react";

import Text from "root/components/Text";
import Section from "root/components/Section";
import Facebook from "root/assets/vectors/facebook.inline.svg";
import Instagram from "root/assets/vectors/instagram.inline.svg";

import "./index.css";

export default function Footer() {
  return (
    <div styleName="root">
      <Section>
        <div styleName="content">
          <div styleName="social">
            <Text fontFamily="proxima-nova" weight="bold">
              SEGUE-NOS
            </Text>
            <a
              href="https://www.facebook.com/Osteovida-531121787095765/"
              aria-label="Instagram"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/osteovida/"
              aria-label="Instagram"
            >
              <Instagram />
            </a>
          </div>
          <div styleName="dash" />
          <div styleName="copyright">
            <Text fontFamily="proxima-nova" weight="bold">
              COPYRIGHT © 2019 OSTEOVIDA
            </Text>
            <Text fontFamily="proxima-nova" weight="bold">
              TODOS OS DIREITOS RESERVADOS
            </Text>
          </div>
        </div>
      </Section>
    </div>
  );
}

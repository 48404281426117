import React from "react";

import Section from "root/components/Section";
import Text from "root/components/Text";
import Button from "root/components/Button";
import InputText from "root/components/InputText";

import "./index.css";

export default function HomeContactForm() {
  return (
    <div styleName="root" id="contactform">
      <Section verticalSpacing={false}>
        <div styleName="copy">
          <Text variant="h1" color="grey" fontFamily="open-sans-cond">
            Marcar Consulta
          </Text>

          <Text color="grey">Todos os campos são obrigatórios</Text>
        </div>

        <form
          styleName="form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="post"
        >
          <input type="hidden" name="bot-field" />
          <div styleName="row">
            <InputText placeholder="Nome" name="name" />
            <InputText placeholder="Sobrenome" name="surname" />
          </div>
          <div styleName="row">
            <InputText placeholder="E-mail" name="email" />
            <InputText placeholder="Contacto Telefónico" name="phone-number" />
          </div>

          <div styleName="message">
            <InputText
              placeholder="A tua mensagem"
              name="message"
              type="textarea"
            />
          </div>

          <div styleName="button">
            <Button Element="button" type="submit">
              MARCAR CONSULTA
            </Button>
          </div>
        </form>
      </Section>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";

import Text from "root/components/Text";

import "./index.css";

function Button({ children, onClick, Element, type }) {
  return (
    <Element styleName="root" onClick={onClick} type={type}>
      <Text fontFamily="proxima-nova" variant="small" weight="bold">
        {children}
      </Text>
    </Element>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  Element: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  onClick: null,
  Element: "div",
  type: "button",
};

export default Button;
